.kontakt {
    &__content-wrapper {
        padding-top: 25px;

        @include breakpoint-min('large-tablet') {
            padding-top: 40px;
        }
        
        h2 {
            text-align: center;
        }
    }

    &__data {
        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 25px;

            @include breakpoint-min('large-tablet') {
                flex-direction: row;
                justify-content: space-evenly;
                margin-top: 40px;
            }

            h2 {
                text-align: center;
            }
        }

        &-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 25px;

            @include breakpoint-min('large-tablet') {
                margin-bottom: 40px;
            }

            h2 {
                color: var($--theme-gray);
            }

            p {
                margin-top: 10px;
                font-size: 1.2rem;
                letter-spacing: 2px;
            }
        }
    }
}