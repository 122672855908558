.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var($--theme-white);
    text-align: center;
}

.content {
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 16px;
        box-sizing: border-box;
        position: relative;

        @include breakpoint-min('tablet') {
            padding: 0 32px;
        }
    }
}