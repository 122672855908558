header {
    background-color: var(--theme-background);
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;

    @include breakpoint-min('small-screen') {
        padding: 5px 0;
    }
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0 6px;

    > .content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: 0;
        padding: 0 16px;
        position: unset;

        @include breakpoint-min('small-screen') {
            justify-content: space-between;
            margin: 0 auto;
        }

        @include breakpoint-min('desktop') {
            padding: 0;
        }
    }
}

.nav {
    &__logo {
        max-width: 185px;
        user-select: none;
        transition: all 200ms;

        &:hover {
            transform: scale(1.1) rotate(2deg);
        }

        @include breakpoint-min('small-screen') {
            max-width: 200px;
            margin-left: 20px;
        }

        @include rwdCustom(1150) {
            max-width: 250px;
            margin-left: 20px;
        }

        @include breakpoint-min('desktop') {
            max-width: 320px;
        }
    }

    &__item {
        text-decoration: none;
        text-align: center;
        color: var(--theme-white);
        font-weight: $boldWeight;
        transition: all $transitionDuration;
        padding: 15px 15px;
        width: auto;
        display: block;
        text-transform: uppercase;
        font-size: 0.75rem;

        @include rwdCustom(1150) {
            white-space: nowrap;
        }

        @include breakpoint-min('desktop') {
            font-size: 0.9rem;
            padding: 20px 20px;
        }

        @include rwdCustom(1550) {
            font-size: 1rem;
            padding: 20px 22px;
        }

        &:hover {
            color: var(--theme-primary);
        }

        &--active {
            border-bottom: 3px solid var(--theme-primary);
        }
    }

    &-desktop {
        display: none;
    
        @include breakpoint-min('small-screen') {
            display: flex;
        }
    }

    &-mobile {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 59px;
        left: 0;
        background-color: var(--theme-background);
        z-index: 99;
        width: auto;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
        padding: 5px 10px 10px;

        @include rwdCustom(350) {
            padding: 5px 25px 10px;
        }

        &--active {
            display: flex;
        }

        @include breakpoint-min('small-screen') {
            display: none;
        }

        &__icon {
            display: block;
            position: absolute;
            top: 21px;
            left: 25px;
            font-size: 32px;
            cursor: pointer;
            color: white;
            transition: all $transitionDuration;
            user-select: none;

            @include breakpoint-min('small-screen') {
                display: none;
            }

            &--active {
                color: var(--theme-primary);
            }
        }
    }
}