.hard-adv-store {
    &__content-wrapper {
        padding-top: 25px;
        padding-bottom: 25px;

        @include breakpoint-min('desktop') {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__text {
        p {
            text-align: left;
            white-space: pre-line;
        }
    }

    &__action-button {
        margin-top: 40px;
    }
}