.banner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    color: var($--theme-text);

    &__content-wrapper {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 75px 16px 45px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        height: auto;
        box-sizing: border-box;

        @include breakpoint-min('tablet') {
            padding: 90px 25px 60px;
        }

        @include rwdCustom(600) {
            padding: 120px 25px 80px;
        }

        @include breakpoint-min('large-tablet') {
            padding: 150px 25px 110px;
        }

        @include breakpoint-min('small-screen') {
            padding: 220px 25px 160px;
        }

        @include breakpoint-min('desktop') {
            padding: 280px 25px 240px;
        }
    }

    &__background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var($--theme-gray);
        width: 100%;
    }

    &__title {
        text-align: left;
        width: 60%;
        font-weight: $superBoldWeight;

        @include breakpoint-min('tablet') {
            width: 50%;
        }

        @include breakpoint-min('large-tablet') {
            width: 45%;
        }

        @include breakpoint-min('small-screen') {
            // width: 35%;
        }

        @include breakpoint-min('desktop') {
            // width: 25%;
        }
    }

    &__tire-tracks {
        display: none;
    
        @include breakpoint-min('large-tablet') {
            display: block;
            position: absolute;
            max-width: 150px;
            left: 25%;
            bottom: -320px;
        }

        @include breakpoint-min('small-screen') {
            max-width: 220px;
            bottom: -420px;
            left: 22%;
        }

        @include breakpoint-min('desktop') {
            left: 25%;
            bottom: -480px;
            max-width: 250px;
        }
    }
}