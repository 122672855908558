// DEV ONLY

// * {
//     outline: 1px solid #f00 !important;
// }

html { 
    overflow-y: auto;
    overflow-x: hidden; 
}

body {
    background: var($--theme-white);
    max-width: 100vw;
    overflow-x: hidden;
}