.partner-tile {
    &__grid-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    &__link-wrapper {

    }

    &__img {
        min-width: 60px;
        min-height: 20px;
        max-width: 130px;
        max-height: 90px;
        margin: 0 10px 15px 0;
        transition: all $transitionDuration;

        @include breakpoint-min('small-screen') {
            margin: 0 15px 20px 0;
        }

        &:hover {
            transform: scale(1.1);
        }

        &--medium {
            max-width: 200px;
            max-height: 90px;
        }

        &--wide {
            max-width: 270px;
            max-height: 90px;
        }
    }
}