.custom-button {
    &__link-wrapper {
        background: var($--theme-background) 0% 0% no-repeat padding-box;
        display: block;
        margin: 0 auto 25px;
        position: relative;
        width: fit-content;
        transition: all $transitionDuration;
        transform: skewX(160deg);

        &:hover {
            background: var($--theme-primary);
        }

        &--disabled {
            background-color: gray;
            pointer-events: none;

            &:hover {
                background: rgba(128, 128, 128, 0.8);
            }
        }
    }

    background: none;
    color: var($--theme-white);
    padding: 12px 30px;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    border: none;
    text-transform: uppercase;
    transform: skewX(20deg);

    @include breakpoint-min('large-tablet') {
        font-size: 1rem;
    }
}