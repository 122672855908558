.galeria {
    &__content-wrapper {
        padding-top: 25px;
        padding-bottom: 25px;

        @include breakpoint-min('desktop') {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__buttons-wrapper {
        display: flex;
        flex-direction: column;

        @include breakpoint-min('tablet') {
            flex-direction: row;
        }
    }

    &__text {
        p {
            text-align: left;
            white-space: pre-line;
        }
    }

    &__action-button {
        margin-top: 40px;
    }
}

.image-gallery-icon {
    filter: invert(1);

    :first-child {
        padding: 5px;
        background: rgba($color: #FFFFFF, $alpha: 0.4);
        border-radius: 5px;
    }
}