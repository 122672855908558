@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');
@import '../abstracts/variables';
@import '../abstracts/mixins';

html, body, * {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: $regularWeight;
    color: var($--theme-text);
    font-size: 0.9rem;
    line-height: 1.2rem;

    @include breakpoint-min('tablet') {
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

.text-color {
    &--primary {
        color: var($--theme-primary);
    }

    &--blue {
        color: var($--theme-blue);
    }
}

.text-weight {
    &--lightWeight {
        font-weight: $lightWeight;
    }

    &--semiBoldWeight {
        font-weight: $semiBoldWeight;
    }

    &--bold {
        font-weight: $boldWeight;
    }

    &--superBold {
        font-weight: $superBoldWeight;
    }
}

.no-wrap {
    white-space: nowrap;
}

h1, h1 > span { 
    font-size: 1.6rem;
    font-weight: $superBoldWeight;
    line-height: 1.8rem;
    color: var($--theme-white);

    @include breakpoint-min('tablet') {
        font-size: 2rem;
        line-height: 2rem;
    }

    @include breakpoint-min('large-tablet') {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }

    @include breakpoint-min('small-screen') {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    @include breakpoint-min('desktop') {
        font-size: 4rem;
        line-height: 4rem;
    }
}

h2, h2 > span { 
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: $superBoldWeight;
    text-align: left;
}

h3 { 
    font-size: 1.15rem;
    line-height: 1.5rem;
    font-weight: $boldWeight;
}