.footer {
    background: var($--theme-background);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    padding: 25px 16px 0px;

    &-content {
        @include breakpoint-min('large-tablet') {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            max-width: $maxWidth;
            margin: 0 auto;
            width: 100%;
        }

        @include breakpoint-min('small-screen') {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            max-width: $maxWidth;
            margin: 0 auto;
            width: 100%;
        }
    }

    &__item {
        text-transform: uppercase;
        margin-bottom: 11px;
        padding-bottom: 4px;
        color: var($--theme-white);
        border-bottom: 2px solid var($--theme-black);
        transition: all $transitionDuration;

        &--active {
            padding-bottom: 3px;
            border-bottom: 3px solid var($--theme-primary);
        }

        &:hover {
            color: var($--theme-primary);
        }
    }

    &-section {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-min('large-tablet') {
            max-width: 46%;
            width: 46%;
        }

        @include breakpoint-min('small-screen') {
            max-width: 23%;
            width: 23%;
        }

        &__title {
            margin-bottom: 15px;
            color: var($--theme-white);
            white-space: nowrap;
        }

        &__social-media {
            a {
                max-width: 10%;

                img {
                    max-width: 100%;
                    transition: all $transitionDuration;
    
                    &:hover {
                        filter: brightness(0.75);
                        transform: scale(1.1);
                        
                        @include breakpoint-min('small-screen') {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }

    &__logo {
        display: none;
        
        @include breakpoint-min('small-screen') {
            display: block;
            max-width: 180px;
            margin: 0 auto;
        }

        img {
            max-width: 100%;
            margin: 0;

            @include breakpoint-min('desktop') {
                max-width: 100%;
            }
        }
    }

    &__author {
        color: #535353;
        font-size: 11px;
        margin: 0 auto 15px;
        font-weight: $lightWeight;

        @include breakpoint-min('small-screen') {
            margin: -25px 50px 15px auto;
        }

        a {
            color: #535353;
            transition: all $transitionDuration;

            &:hover {
                color: var($--theme-primary);
            }
        }
    }

    &__social-media {
        &-logo {
            margin-bottom: 15px;
        }
    }
}