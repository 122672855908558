// --- colors

$--theme-primary: --theme-primary;
$--theme-primary-pale: --theme-primary-pale;
$--theme-red: --theme-red;
$--theme-green: --theme-green;
$--theme-white: --theme-white;
$--theme-black: --theme-black;
$--theme-background: --theme-background;
$--theme-text: --theme-text;
$--theme-gray: --theme-gray;
$--theme-blue: --theme-blue;

// Light theme (default)
$theme-map-light: (
    $--theme-primary: #fd3219,
    $--theme-primary-pale: #ff8c7d,
    $--theme-black: #000000,
    $--theme-red: red,
    $--theme-green: #00ff0d,
    $--theme-white: #ffffff,
    $--theme-background: #000000,
    $--theme-text: #000000,
    $--theme-gray: #888888,
    $--theme-blue: #0066ff,
);

$neutral: #AFBAC5;

// --- typography

$lightWeight: 300;
$regularWeight: 400;
$semiBoldWeight: 600;
$boldWeight: 700;
$superBoldWeight: 800;

// --- breakpoints

$breakpoints: (
    'tablet':  ( min-width:  481px ),
    'large-tablet':  ( min-width:  769px ),
    'small-screen': ( min-width:  992px ),
    'desktop':  ( min-width: 1400px )
) !default;

// --- others

$maxWidth: 1400px;
$transitionDuration: 120ms;