.contact-section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    color: var($--theme-text);

    &__background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var($--theme-gray);
        width: 100%;
    }

    &__tire-tracks {
        display: none;

        @include breakpoint-min('large-tablet') {
            display: block;
            position: absolute;
            bottom: 0;
            right: 10%;
            max-width: 150px;
        }
    }

    &__content-wrapper {
        max-width: $maxWidth;
        margin: 0 auto;
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        height: auto;
        box-sizing: border-box;

        h2 {
            color: var($--theme-white);
            text-align: center;
        }

        &--inner {
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: nowrap;

            @include breakpoint-min('large-tablet') {
                flex-direction: row;
                justify-content: center;
            }
        }
    }

    &__text-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-min('large-tablet') {
            width: 38%;
            align-items: flex-start;
        }

        a {
            color: var($--theme-white);
            transition: all $transitionDuration;

            &:hover {
                color: var($--theme-primary);
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-wrapper {
            @include breakpoint-min('large-tablet') {
                width: 58%;
                margin-top: 25px;
            }
        }

        &-button {
            background: none;
            color: var($--theme-white);
            padding: 12px 30px;
            cursor: pointer;
            font-weight: 700;
            font-size: 13px;
            border: none;
            text-transform: uppercase;
            transform: skewX(20deg);
            transition: all $transitionDuration;

            @include breakpoint-min('large-tablet') {
                font-size: 1rem;
            }

            &--wide {
                min-width: 230px;
                text-align: left;
            }

            &--wrapper {
                // background: #FFFFFF57 0% 0% no-repeat padding-box;
                background: #FFFFFF57;
                display: block;
                margin: 0 auto 25px;
                position: relative;
                width: fit-content;
                transition: all $transitionDuration;
                transform: skewX(160deg);

                &:hover {
                    background: var($--theme-primary);
                }
            }
        }

        &-response {
            color: var($--theme-white);
            white-space: pre-line;
            word-break: break-word;
            max-width: 90%;

            &--success {
                color: var($--theme-green);
            }

            &--error {
                color: var($--theme-primary);
            }
        }

        input, textarea {
            width: 80%;
            margin-bottom: 25px;
            background: #FFFFFF57 0% 0% no-repeat padding-box;
            border: 3px solid var($--theme-primary);
            opacity: 1;
            padding: 10px 15px;
            font-weight: $boldWeight;
            color: var($--theme-white);
            outline: none;

            &::placeholder {
                color: var($--theme-white);
            }
        }

        textarea {
            min-height: 130px;
            resize: vertical;
            max-height: 500px;
        }
    }
}