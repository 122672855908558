.not-found {
    &__content-wrapper {
        min-height: 400px;
        justify-content: flex-start;
    }

    &__h3 {
        margin-top: 50px; 
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: $boldWeight;

        @include breakpoint-min('large-tablet') {
            margin-top: 100px; 
        }
    }

    &__tire-tracks {
        max-width: 100px;
        position: absolute;
        top: 70px;
        z-index: 0;

        @include breakpoint-min('large-tablet') {
            max-width: 150px;
            top: 75px;
        }

        @include breakpoint-min('small-screen') {
            max-width: 200px;
            top: 60px;
        }

        &--1 {
            left: 15%;
            transform: rotate(320deg);
        }

        &--2 {
            right: 15%;
            transform: rotate(40deg);
        }
    }
}