.text-tile {
    &__wrapper {
        background-color: var($--theme-white);
        clip-path: polygon(100% 5%, 100% 95%, 0 100%, 0 0);
        color: var($--theme-black);
        padding: 35px 25px;
        margin-bottom: 25px;
        width: 70%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex-grow: 1;
        height: 100%;

        @include breakpoint-min('tablet') {
            width: 60%;
        }

        @include breakpoint-min('large-tablet') {
            margin-bottom: 0;
            margin-right: 20px;
        }

        @include breakpoint-min('small-screen') {
            margin-right: 30px;
        }

        @include breakpoint-min('desktop') {
            margin-right: 60px;
        }

        &:last-of-type {
            margin-bottom: 0;

            @include breakpoint-min('large-tablet') {
                margin-right: 0;
            }
        }
    }

    &__content {
        &-wrapper {
            p {
                margin-bottom: 15px;
            }

            h2 {
                text-align: center;
                margin-bottom: 25px;
            }

            h3 {
                text-align: center;
                margin-top: -25px;
                margin-bottom: 25px;
            }
        }
    }

    &__rozklad-jazdy {
        @include breakpoint-min('large-tablet') {
            margin-bottom: 25px;
            margin-right: 0;
        }

        @include breakpoint-min('small-screen') {
            width: 40%;
        }

        &:nth-child(odd) {
            @include breakpoint-min('small-screen') {
                margin-right: 25px;
            }

            @include breakpoint-min('desktop') {
                margin-right: 60px;
            }
        }

        &--mobile {
            margin-top: 25px;

            @include breakpoint-min('small-screen') {
                display: none;
            }
        }

        &--desktop {
            display: none;
            width: 25%;
            margin-left: 25px;

            @include breakpoint-min('small-screen') {
                display: block;
            }

            @include breakpoint-min('desktop') {
                margin-left: 60px;
            }
        }
    }
}