.zostan-sponsorem {
    &__content-wrapper {
        @include breakpoint-min('small-screen') {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__text {
        padding: 25px 0 0;
        
        @include breakpoint-min('small-screen') {
            width: 48%;
            margin-top: 20%;
        }

        p, h3 {
            margin-bottom: 10px;
            text-align: left;
        }

        p {
            &:last-of-type {
                margin-bottom: 16px;
            }
        }
    }

    &__grid-wrapper {
        margin-bottom: 25px;
        
        @include breakpoint-min('small-screen') {
            margin-top: 25px;
            width: 48%;
        }
    }

    &__tire-tracks {
        max-width: 250px;

        &--wrapper {
            display: none;
    
            @include breakpoint-min('small-screen') {
                display: block;
                width: 240px;
                height: 240px;
                position: absolute;
                right: 16px;
                bottom: 0px;
                overflow: hidden;
            }

            @include breakpoint-min('desktop') {
                right: 0;
            }
        }
    }
}