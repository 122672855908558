.rozklad-jazdy {
    &__content-wrapper {
        @include breakpoint-min('small-screen') {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }
    }

    &-section-1 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &__image {
            display: none;

            @include breakpoint-min('small-screen') {
                display: block;
                max-width: 30%;
                max-height: 450px;
            }
        }

        &__text {
            padding: 25px 16px 10px;
            max-width: calc($maxWidth - 30%);
            
            @include breakpoint-min('tablet') {
                padding: 25px 32px 10px;
            }

            @include breakpoint-min('small-screen') {
                padding: 50px 32px 50px;
                margin-right: 20px;
                margin-left: auto;
            }

            @include breakpoint-min('desktop') {
                padding: 80px 32px 50px;
                margin-right: 50px;
            }

            p {
                text-align: left;
                margin-bottom: 15px;
            }

            h3 {
                text-align: left;
                margin-bottom: 15px;
            }
        }
    }

    &-section-2 {
        width: 100%;
        position: relative;

        &__tire-tracks {
            display: none;

            @include breakpoint-min('small-screen') {
                display: block;
                position: absolute;
                top: -100px;
                left: 2%;
                max-width: 200px;
            }
            
            @include breakpoint-min('desktop') {
                left: 10%;
                top: -200px;
            }

            @include rwdCustom(1700) {
                left: 10%;
                top: -350px;
            }

            &--second {
                left: unset;
                right: 2%;

                @include breakpoint-min('small-screen') {
                    top: unset;
                    bottom: -200px;
                }

                @include breakpoint-min('desktop') {
                    top: unset;
                    bottom: -220px;
                    right: 7%;
                }
            }
        }

        &__background {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: var($--theme-gray);
            width: 100%;
        }

        &__content-wrapper {
            max-width: $maxWidth;
            min-height: 150px;
            margin: 0 auto;
            padding: 45px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            width: 100%;
            height: auto;
            box-sizing: border-box;

            @include breakpoint-min('large-tablet') {
                padding: 65px 25px;
            }

            @include breakpoint-min('small-screen') {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            @include breakpoint-min('desktop') {
                padding: 85px 0;
            }
            
            > .section-title__wrapper {
                width: 100%;

                h2 {
                    text-align: center;
                    color: var($--theme-white);
                }
            }
        }

        &__text-tiles-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            @include breakpoint-min('small-screen') {
                flex-direction: row;
                flex-wrap: wrap;
                width: 70%;
            }

            &--pair {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                width: 100%;

                @include breakpoint-min('small-screen') {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-section-3 {
        padding-top: 25px;
        padding-bottom: 10px;

        @include breakpoint-min('small-screen') {
            width: 75%;
            margin-right: auto;
            padding-top: 45px;
            padding-bottom: 30px;
        }

        p {
            text-align: left;
            margin-bottom: 15px;

            a {
                color: var($--theme-primary);
                font-weight: $boldWeight;
                transition: all $transitionDuration;

                &:hover {
                    color: var($--theme-gray);
                }
            }
        }
    }

    &__tire-tracks {
        max-width: 250px;

        &--wrapper {
            display: none;
    
            @include breakpoint-min('small-screen') {
                // display: block;
                width: 240px;
                height: 240px;
                position: absolute;
                left: 16px;
                bottom: 0px;
                overflow: hidden;
            }

            @include breakpoint-min('desktop') {
                right: 0;
            }
        }
    }
}