.home {
    &-section-1 {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 25px;

        @include breakpoint-min('large-tablet') {
            flex-direction: row;
            justify-content: center;
        }

        &__images {
            position: relative;
            width: 100%;
            height: 300px;

            @include rwdCustom(420) {
                height: 340px;
            }

            @include breakpoint-min('tablet') {
                height: 380px;
            }

            @include rwdCustom(560) {
                height: 420px;
            }

            @include rwdCustom(660) {
                height: 480px;
            }

            @include breakpoint-min('large-tablet') {
                width: 50%;
            }

            img {
                max-width: 40%;
                position: absolute;

                @include breakpoint-min('large-tablet') {
                    max-width: 70%;
                }
                
                @include breakpoint-min('small-screen') {
                    max-width: 60%;
                    max-height: 380px;
                }
            }

            &--img-1 {
                top: 0;
                left: -10%;

                @include breakpoint-min('large-tablet') {
                    top: 30px;
                    left: -25%;
                }

                @include breakpoint-min('small-screen') {
                    top: 50px;
                }
            }

            &--img-2 {
                top: 120px;
                left: 20%;
                max-width: 50% !important;

                @include breakpoint-min('large-tablet') {
                    max-width: 70% !important;
                    top: 220px;
                }

                @include breakpoint-min('small-screen') {
                    max-width: 65% !important;
                    max-height: 360px !important;
                    top: 280px;
                }

                @include rwdCustom(1100) {
                    max-height: 320px !important;
                }
            }
            
            &--img-3 {
                top: 30px;
                right: -6%;

                @include breakpoint-min('large-tablet') {
                    top: 450px;
                    right: unset;
                    left: -10%;
                }

                @include breakpoint-min('small-screen') {
                    top: 550px;
                }
            }
        }

        &__text {
            padding: 25px 0 0;

            @include breakpoint-min('large-tablet') {
                width: 50%;
            }

            p, h3 {
                margin-bottom: 10px;
                text-align: left;
            }

            p {
                &:last-of-type {
                    margin-bottom: 16px;
                }
            }
        }

        &__button-link-wrapper {
            @include breakpoint-min('large-tablet') {
                margin-top: 20px;
            }

            @include breakpoint-min('small-screen') {
                margin-top: 40px;
            }

            &--red-border {
                box-sizing: border-box;
                border: 3px solid var($--theme-primary);

                @include breakpoint-min('small-screen') {
                    border: 4px solid var($--theme-primary);
                }
            }

            > .custom-button {
                @include breakpoint-min('large-tablet') {
                    font-size: 1.1rem;
                    padding: 17px 37px;
                }

                @include breakpoint-min('small-screen') {
                    font-size: 1.2rem;
                    padding: 22px 40px;
                }
            }
        }
    }

    &-section-2 {
        margin-bottom: 10px;
        position: relative;

        @include breakpoint-min('large-tablet') {
            width: 70%;
            margin: 0 0 0 auto;
            display: block;
            margin-bottom: 25px;
        }

        @include breakpoint-min('small-screen') {
            width: 60%;
            margin-bottom: 30px;
            margin-top: 35px;
            margin-right: 12%;
        }

        @include rwdCustom(1050) {
            margin-top: 65px;
        }

        @include rwdCustom(1250) {
            width: 55%;
            margin-right: 15%;
            margin-bottom: 35px;
        }

        @include breakpoint-min('desktop') {
            width: 45%;
            margin-right: 20%;
            margin-bottom: 45px;
        }

        h2 {
            text-align: center;
        }

        &__tire-tracks {
            display: none;

            @include breakpoint-min('large-tablet') {
                display: block;
                position: absolute;
                bottom: -120px;
                left: -30%;
                max-width: 150px;
                transform: rotate(30deg);
            }

            @include breakpoint-min('small-screen') {
                left: unset;
                right: -20%;
                bottom: unset;
                top: 0;
                transform: rotate(0deg);
            }

            @include rwdCustom(1250) {
                max-width: 250px;
                top: -200px;
                right: -30%;
            }

            @include breakpoint-min('desktop') {
                right: -40%;
            }
        }
    }
}