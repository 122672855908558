.faq {
    &__content-wrapper {
        padding-top: 25px;
        padding-bottom: 25px;

        @include breakpoint-min('desktop') {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__text {
        margin-bottom: 25px;

        p {
            text-align: left;
        }
    }
}